<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Bots</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3">
        <b-button size="sm" variant="primary" @click="showModal">
          <b-icon-plus style="font-size: 1.2em" />
          Create
        </b-button>
      </b-col>
    </b-row>
    <div ref="table-container">
      <small class="text-muted"
        >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
      <div v-if="isBusy" class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
      <b-table
        bordered
        small
        striped
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data"
          >{{ 1 + data.index + (currentPage - 1) * perPage }}
        </template>
        <template v-slot:cell(action)="data">
          <b-icon-pencil-square
            class="btn-inline-text"
            @click="goto(data.item.botId)"
            title="Bot Settings"
          ></b-icon-pencil-square>
          <b-icon-trash
            class="btn-inline-text ml-1"
            @click="deleteItem(data.item)"
            title="Delete"
          ></b-icon-trash>
          <b-icon-list-ul
            class="btn-inline-text ml-1"
            @click="showLogs(data.item.botId)"
            title="Show Logs"
          ></b-icon-list-ul>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
    </div>

    <b-modal
      v-model="modal"
      centered
      size="xl"
      ref="form-modal"
      title="Create Bot"
      id="main-modal"
    >
      <b-form ref="main-form">
      <b-row class="mb-2">
          <b-col cols="!2" lg="3">
            <label>Bot Name</label>
          </b-col>
          <b-col cols="!2" lg="9">
            <b-input
              v-focus
              class="form-control-sm"
              v-model.trim="formItem.botName"
              required
              @change="$sanitize('formItem.botName')"
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="!2" lg="3">
            <label>Bot Language</label>
          </b-col>
          <b-col cols="!2" lg="9">
            <b-form-select
              size="sm"
              v-model="formItem.botLanguage"
              :options="languages"
              value-field="languageId"
              text-field="languageName"
            ></b-form-select>
          </b-col>
        </b-row>
      <hr>

      <!-- Advanced Configuration Section -->
      <h5>Bot Configurations</h5>
      <br />
      <advanced-settings 
        :botMarioConfig="botMarioConfig" 
        @update:botMarioConfig="value => botMarioConfig = value" 
      />
    </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="modal = false">Cancel</b-button>
        <b-button variant="primary" @click="promptConfirm">Submit</b-button>
      </template>
    </b-modal>

    <!--<b-modal
      v-model="modal"
      centered
      size="xl"
      ref="form-modal"
      title="Create Bot"
      id="main-modal"
    >
      <b-form ref="main-form">
        <b-row class="mb-2">
          <b-col cols="!2" lg="3">
            <label>Bot Name</label>
          </b-col>
          <b-col cols="!2" lg="9">
            <b-input
              v-focus
              class="form-control-sm"
              v-model.trim="formItem.botName"
              required
              @change="$sanitize('formItem.botName')"
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="!2" lg="3">
            <label>Bot Language</label>
          </b-col>
          <b-col cols="!2" lg="9">
            <b-form-select
              size="sm"
              v-model="formItem.botLanguage"
              :options="languages"
              value-field="languageId"
              text-field="languageName"
            ></b-form-select>
          </b-col>
        </b-row>
        <section>
          <span
            class="btn-advance-settings"
            @click="toggleAdvanceSettings = !toggleAdvanceSettings"
          >
            <b-icon-caret-right-fill/> Configuration Settings
          </span>
          <b-collapse v-model="toggleAdvanceSettings">
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Url Path</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="urlPath"
                  v-focus
                  class="form-control-sm"
                  type="url"
                  v-model.trim="botMarioConfig.urlPath"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.urlPath')"
                  placeholder="Enter a valid URL (http/https)"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Db Profile</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="dbProfile"
                  v-focus
                  class="form-control-sm"
                  v-model.trim="botMarioConfig.dbProfile"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.dbProfile')"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Client</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="client"
                  v-focus
                  class="form-control-sm"
                  v-model.trim="botMarioConfig.client"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.client')"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Title</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="title"
                  v-focus
                  class="form-control-sm"
                  v-model.trim="botMarioConfig.title"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.title')"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Api Paths</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="apiPaths"
                  v-focus
                  class="form-control-sm"
                  type="url"
                  v-model.trim="botMarioConfig.apiPaths"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.apiPaths')"
                  placeholder="Enter a valid URL (http/https)"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Plugin Paths</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="pluginPaths"
                  v-focus
                  class="form-control-sm"
                  type="url"
                  v-model.trim="botMarioConfig.pluginPaths"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.pluginPaths')"
                  placeholder="Enter a valid URL (http/https)"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Socket Paths</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="socketPaths"
                  v-focus
                  class="form-control-sm"
                  v-model.trim="botMarioConfig.socketPaths"
                  :required="toggleAdvanceSettings"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Access Token</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="accessToken"
                  v-focus
                  class="form-control-sm"
                  v-model.trim="botMarioConfig.accessToken"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.accessToken')"
                ></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="!2" lg="3">
                <label>Brand</label>
              </b-col>
              <b-col cols="!2" lg="9">
                <b-input
                  name="brandId"
                  v-focus
                  class="form-control-sm"
                  v-model.trim="botMarioConfig.brandId"
                  :required="toggleAdvanceSettings"
                  @change="$sanitize('botMarioConfig.brandId')"
                  placeholder="eg. welton, vpo, masuta"
                ></b-input>
              </b-col>
            </b-row>
          </b-collapse>
        </section>
        <section>
          <span
            class="btn-advance-settings"
            @click="toggleSalesforceSettings = !toggleSalesforceSettings"
          >
            <b-icon-caret-right-fill/> Live Chat Settings
          </span>
          <b-collapse v-model="toggleSalesforceSettings">
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Salesforce Url</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.salesforceUrl"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>GBQ Api Url</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.gbqApiUrl"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>GBQ Api Authentication</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.gbqApiAuthentication"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>X-Api Key</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.xApiKey"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Organisation Id</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.organisationId"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Organisation Id</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.organisationId"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Deployment Id</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.deploymentId"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Version</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.version"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Affinity</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.affinity"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Agent Reconnect Buffer</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.agentReconnectBuffer"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Kzing Brand</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.kzingBrand"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Default Values</label>
              </b-col>
              <b-col cols="12" xl="9">
                <custom-values v-model="botMarioConfig.defaultValues" 
                               :required="toggleSalesforceSettings"/>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Brand Market</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.brandMarket"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Brand Name</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.brandName"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Customer</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.customer"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Mobile Api Type</label>
              </b-col>
              <b-col cols="12" xl="9">
                <b-input class="form-control-sm"
                           v-model.trim="botMarioConfig.mobileApiType"
                           :required="toggleSalesforceSettings">
                </b-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Message</label>
              </b-col>
              <b-col cols="12" xl="9">
                <custom-values v-model="botMarioConfig.message" 
                               :required="toggleSalesforceSettings"/>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col cols="12" xl="3">
                <label>Mobile Api Detail</label>
              </b-col>
              <b-col cols="12" xl="9">
                <v-json-editor v-model="botMarioConfig.mobileApiDetail"
                               :options="{ mode: 'code', mainMenuBar: false }" />
              </b-col>
            </b-row>
          </b-collapse>
        </section>
      </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="modal = false">Cancel</b-button>
        <b-button variant="primary" @click="promptConfirm">Submit</b-button>
      </template>
    </b-modal>-->

    <b-modal size="lg" v-model="auditModal" centered hide-footer>
      <audit-history :audit-histories="auditHistories"></audit-history>
    </b-modal>
  </div>
</template>

<script>
/*global _*/
import httpclient from '@/mixins/HttpHandler';
import AuditHistory from '@/components/Audit/AuditHistory';
//import CustomValues from '@/components/Intents/CustomValues';
//import VJsonEditor from '@/components/UI/JsonEditor';
import AdvancedSettings from '@/components/Bots/BotConfigurations';
// import BotSkypeConfigs from '@/components/Bots/BotSkypeConfigs.vue';

export default {
  mixins: [httpclient],
  components: { 
      AuditHistory,
      //CustomValues,
      //VJsonEditor,
      AdvancedSettings,
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'main-modal') {
        this.$refs['main-form'].querySelector('input').focus();
        //this.$refs['main-form'].$el.querySelector('input')?.focus();
      }
    });
    this.getList();
  },
  data() {
    return {
      modal: false,
      auditModal: false,
      languages: [
        { languageId: 'English', languageName: 'English' },
        { languageId: 'Chinese', languageName: 'Chinese' },
        { languageId: 'Vietnamese', languageName: 'Vietnamese' },
        { languageId: 'Thai', languageName: 'Thai' },
      ],
      // botTypes: [
      //   { value: 0, text: 'Old Bot' },
      //   { value: 1, text: 'Mario' },
      // ],
      path: 'bots',
      formItem: {
        botId: 0,
        botName: '',
        botLanguage: 'English',
        botType: 1,
        customValues: '',
      },
      preChat: {
        botId: -1,
        enablePreChat: false,
        greetingMessage: '',
        usernamePlaceholder: '',
        invalidUsernameAlertMessage: '',
        submitButtonText: 'Submit',
        enablePreChatBanner: false,
        prechatBannerUrl: '',
        bannerFileName: '',
        bannerFileBytes: '',
      },
      preChatBanner: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
    },
      answerTextDefault: {
        answer: JSON.stringify({
        type: 'text',
        content: {
          message: 'Please rate you chat experience: ',
          links: [],
          },
        }),
        weight: 1,
        answerType: 1,
      },
      formFieldsDefault: [
      {
        name: 'Rating',
        isRequired: true,
        type: 'rating',
        scales: [
          { name: 'Scale 5', text: 'Excellent', isVisible: true, value: 5 },
          { name: 'Scale 4', text: 'Very Good', isVisible: true, value: 4 },
          { name: 'Scale 3', text: 'Good', isVisible: true, value: 3 },
          { name: 'Scale 2', text: 'Fair', isVisible: true, value: 2 },
          { name: 'Scale 1', text: 'Poor', isVisible: true, value: 1 },
        ],
        compulsory: true,
      },
      {
        name: 'Comment',
        isRequired: true,
        type: 'textArea',
        compulsory: true,
      },
    ],
      botSkypeConfig: {
        skypeUserName: '',
        skypePassword: '',
        skypeUserId: '',
        skypeDisplayName: '',
        csSkypeUserName: '',
        brand: '',
        messageEmptyRequest: '',
        messageFileDownloaded: '',
      },
      botMarioConfig: {
          urlPath: '',
          dbProfile: '',
          client: '',
          title: '',
          apiPaths: '',
          pluginPaths: '',
          socketPaths: '',
          accesssToken: '',
          brandId: '',
          salesforceUrl: '',
          gbqApiUrl: '',
          gbqApiAuthentication: '',
          xApiKey: '',
          organisationId: '',
          deploymentId: '',
          version: '',
          affinity: '',
          agentReconnectBuffer: '',
          kzingBrand: '',
          defaultValues: '',
          brandMarket: '',
          brandName: '',
          customer: '',
          mobileApiType: '',
          message: '',
          mobileApiDetail: {}
      },
      auditHistories: [],
      fields: [
        {
          key: 'index',
          label: 'No.',
          sortable: false,
        },
        {
          key: 'botName',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'botLanguage',
          label: 'Language',
          sortable: true,
        },
        {
          key: 'intentCount',
          label: 'Intent Count',
          sortable: true,
        },
        {
          key: 'botId',
          label: 'Bot ID',
          sortable: true,
        },
        {
          key: 'accountId',
          label: 'Account ID',
          sortable: true,
        },
        {
          key: 'action',
          label: '',
          sortable: false,
        },
      ],
      items: [],
      perPage: 100,
      currentPage: 1,
      isBusy: false,
      toggleAdvanceSettings: false,
      toggleSalesforceSettings: false
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    showModal() {
      this.formItem = _defaultFormItem();
      this.botSkypeConfig = _defaultBotSkypeConfig();
      this.formItem.options = [''];
      this.modal = true;
      this.botMarioConfig = _defaultBotMarioConfig();
    },
    getList() {
      const vm = this;
      this.httpclient.get('api/bots').then(function (resp) {
        if (resp.data) {
          const data = resp.data;
          if (data.botMarioConfig) {
              if (data.botMarioConfig.defaultValues) {
                  let parsedValues = JSON.parse(data.botMarioConfig.defaultValues);
                  data.botMarioConfig.defaultValues = Object.entries(parsedValues).map(([key, values]) => {
                      return {
                          key: key,
                          value: values.join(", ")
                      };
                  });
              } else {
                  data.botMarioConfig.defaultValues = [];
              }

              if (data.botMarioConfig.message) {
                  let customObj = JSON.parse(data.botMarioConfig.message);
                  if (_.isObject(customObj) && !_.isArray(customObj)) {
                      let message = [];
                      _.forEach(customObj, function (value, key) {
                          message.push({ key: key, value: value });
                      });
                      data.botMarioConfig.message = message || [];
                  } else {
                      data.botMarioConfig.message = [];
                  }
              } else {
                  data.botMarioConfig.message = [];
              }

              data.botMarioConfig.mobileApiDetail = JSON.parse(data.botMarioConfig.mobileApiDetail) || {};

              if (Array.isArray(data.botMarioConfig.socketPaths)) {
                  data.botMarioConfig.socketPaths = data.botMarioConfig.socketPaths.join(", "); // Convert array to a comma-separated string for display
              }
              if (Array.isArray(data.botMarioConfig.kzingBrand)) {
                  data.botMarioConfig.kzingBrand = data.botMarioConfig.kzingBrand.join(", "); // Convert array to a comma-separated string for display
              }
          }
          vm.items = data;
          vm.modal = false;
        }
      });
    },
    deleteItem(data) {
      const vm = this;
      var confirmMessage =
        'Delete the bot? This will also delete all data related to this bot. Please make sure that this is the bot to delete. This action is not reversible.';
      var id = data.botId;

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.httpclient.delete('api/bots/' + id).then(function (resp) {
              if (resp.data) {
                var index = _.findIndex(vm.items, { botId: id });
                vm.items.splice(index, 1);
                vm.modal = false;
                vm.$bvModal.msgBoxOk('Bot had been deleted successfully', {
                  okVariant: 'success',
                  centered: true,
                });
              }
            });
          }
        });
    },
    promptConfirm() {
      const vm = this;
      var mainForm = vm.$refs['main-form'];

      if (!mainForm.reportValidity()) {
        return;
      }

      vm.modal = false;

      var confirmMessage = 'Create the bot?';

      this.$bvModal
        .msgBoxConfirm(confirmMessage, { centered: true })
        .then(function (confirm) {
          if (confirm) {
            vm.addItem();
          } else {
            vm.modal = true;
          }
        });
    },
    addItem() {
      const vm = this;
      const formItem = { ...this.formItem };
      formItem.botSkypeConfig = { ...this.botSkypeConfig };
      //formItem.botMarioConfig = { ...this.botMarioConfig };

      formItem.botMarioConfig = { ...this.botMarioConfig };
      const preChat = { ...this.preChat};
      const preChatBanner = { ...this.preChatBanner};
      const answerTextDefault = { ...this.answerTextDefault};
      const formFieldsDefault = this.formFieldsDefault;

      formItem.botMarioConfig.defaultValues = formItem.botMarioConfig.defaultValues.reduce((acc, { key, value }) => {
        // Only include non-empty keys and values
        if (key && value) {
          if (!acc[key]) {
            acc[key] = [];
          }
        //acc[key].push(value);  // Add the value to the respective array
        acc[key].push(...value.split(',').map((v) => v.trim()));
        }
        return acc;
      }, {});
      formItem.botMarioConfig.defaultValues = JSON.stringify(formItem.botMarioConfig.defaultValues);

      formItem.botMarioConfig.mobileApiDetail = JSON.stringify(formItem.botMarioConfig.mobileApiDetail);

      let customObj = _.reduce(
        formItem.botMarioConfig.message,
        (obj, { key, value }) => ({ ...obj, [key]: value }),
        {}
      );
      formItem.botMarioConfig.message = JSON.stringify(customObj);

    if (typeof formItem.botMarioConfig.kzingBrand === 'string') {
        formItem.botMarioConfig.kzingBrand = formItem.botMarioConfig.kzingBrand
            .split(",") 
            .map(item => item.trim()) 
            .filter(item => item); 
    }

    if (typeof formItem.botMarioConfig.socketPaths === 'string') {
        formItem.botMarioConfig.socketPaths = formItem.botMarioConfig.socketPaths
            .split(",")
            .map(item => item.trim())
            .filter(item => item);
    }

    preChat.bannerFileName = preChatBanner.fileName;
    preChat.bannerFileBytes = preChatBanner.fileBytes;
    preChat.preChatBannerUrl = preChatBanner.originalFileUrl;

      vm.httpclient.post('api/bots', formItem).then(function (resp) {
        if (resp.data) {
            preChat.botId = resp.data.bot;

            // Proceed with the success message for bot creation
            vm.getList();
            vm.modal = false;

            // Show success message first
            vm.$bvModal.msgBoxOk('Bot had been created successfully', {
                okVariant: 'success',
                centered: true,
            }).then(() => {
                // Now call the preChat API after the modal has closed
                vm.httpclient.post('/api/prechat', preChat);

                // Prepare chat-rating data
                let answers = [];
                answers.push(answerTextDefault);
                answers.push({
                    answer: JSON.stringify({
                        type: 'form',
                        content: {
                            text: '',
                            message: '',
                            ifNeedConfirm: true,
                            fields: formFieldsDefault,
                        },
                    }),
                    weight: 1,
                    answerType: 2,
                });

                const systemMessage = {
                    botId: resp.data.bot,
                    systemMessageType: 'chatrating',
                    intentAnswers: answers,
                };

                // Call chat-rating API after the modal is shown
                vm.httpclient.post(`api/bots/${resp.data.bot}/chat-rating`, systemMessage);
            });
        }
    })
    },
    showLogs(botId) {
      const vm = this;
      vm.httpclient.get('api/bots/' + botId + '/logs').then(function (resp) {
        if (resp.data) {
          vm.auditHistories = resp.data;
          vm.auditModal = true;
        }
      });
    },
    goto(botId) {
      this.$store.commit('updateSelectedBotId', botId);
      this.$router.push({ name: 'bot-settings' });
    },
  },
};

const _defaultBotSkypeConfig = () => {
  return {
    skypeUserName: '',
    skypePassword: '',
    skypeUserId: '',
    skypeDisplayName: '',
    csSkypeUserName: '',
    brand: '',
    messageEmptyRequest: '',
    messageFileDownloaded: '',
  };
};

const _defaultBotMarioConfig = () => {
  return {
    urlPath: '',
    dbProfile: '',
    title: '',
    apiPaths: '',
    pluginPaths: '',
    socketPaths: '',
    accesssToken: '',
    brandId: '',
    salesforceUrl: '',
    gbqApiUrl: '',
    gbqApiAuthentication: '',
    xApiKey: '',
    organisationId: '',
    deploymentId: '',
    version: '',
    affinity: '',
    agentReconnectBuffer: '',
    kzingBrand: '',
    defaultValues: [],
    brandMarket: '',
    brandName: '',
    customer: '',
    mobileApiType: '',
    message: [],
    mobileApiDetail: {}
  };
};

const _defaultFormItem = () => {
  return {
    botId: 0,
    botName: '',
    botLanguage: 'English',
    botType: 1,
    customValues: '{}'
  };
};
</script>

<style lang="scss" scoped>
@import 'src/variables.scss';

.btn-inline-text {
  &:hover {
    @extend %clickable;
  }
}
</style>
